/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "hide-header": "",
        "hide-footer": "",
        centered: "",
        "no-close-on-backdrop": "",
        size: "lg"
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "b-row",
            { staticClass: "mb-4", attrs: { "align-h": "between" } },
            [
              _c("b-col", { attrs: { cols: "10" } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/icon_logo_violet.svg"),
                    alt: ""
                  }
                })
              ]),
              _c("b-col", { staticClass: "text-right" }, [
                _c(
                  "a",
                  {
                    staticClass: "tw-text-eclipse",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.showDialog = false
                      }
                    }
                  },
                  [
                    _c("b-icon", {
                      attrs: { icon: "x-circle", "font-scale": "1.5" }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "b-alert",
            {
              attrs: { variant: "warning" },
              model: {
                value: _vm.alert.show,
                callback: function($$v) {
                  _vm.$set(_vm.alert, "show", $$v)
                },
                expression: "alert.show"
              }
            },
            [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]
          ),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "3" } }),
              _c(
                "b-col",
                { attrs: { cols: "9" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "mb-3", attrs: { cols: "12" } },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "heading mb-0",
                              staticStyle: { "font-size": "1.5rem" }
                            },
                            [
                              _vm._v(
                                " What should be done with this prescription's timer? "
                              )
                            ]
                          )
                        ]
                      ),
                      _c("b-col", { staticClass: "mb-5" }, [
                        _c("div", [
                          _c(
                            "span",
                            { staticClass: "text-primary text-small" },
                            [_vm._v("Patient")]
                          ),
                          _c("br"),
                          _c("span", [
                            _vm._v(_vm._s(_vm.prescriptionData.fullname))
                          ])
                        ]),
                        _c("div", [
                          _c(
                            "span",
                            { staticClass: "text-primary text-small" },
                            [_vm._v("Prescription")]
                          ),
                          _c("br"),
                          _c("span", [
                            _vm._v(_vm._s(_vm.prescriptionData.medicinename))
                          ])
                        ])
                      ]),
                      _c(
                        "b-col",
                        { staticClass: "mb-3", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                block: "",
                                variant: "primary",
                                pill: ""
                              },
                              on: { click: _vm.bypassDelay }
                            },
                            [_vm._v(" Pause ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "mb-3", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                block: "",
                                variant: "outline-primary",
                                pill: ""
                              },
                              on: {
                                click: function($event) {
                                  _vm.showDialog = false
                                }
                              }
                            },
                            [_vm._v(" Cancel ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }