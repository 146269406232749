//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from 'moment';
import { PrescriptionService } from '@/services/prescription.service';
export default {
  props: {
    prescriptionData: {
      type: Object,

      default() {
        return {};
      }

    }
  },
  watch: {
    showDialog(show) {
      if (!show) {
        this.patientid = '';
      }
    }

  },

  data() {
    return {
      showDialog: false,
      alert: {
        show: false,
        message: ''
      },
      patientid: '',
      processing: false,
      loading: false
    };
  },

  methods: {
    showAlert(msg) {
      this.alert.show = true;
      this.alert.message = msg;
    },

    async bypassDelay() {
      this.alert.show = false;

      try {
        this.processing = true;
        const response = await PrescriptionService.stopTimerDelay(this.patientid, this.prescriptionData.id);
        this.$emit('clear-timer', this.prescriptionData.id);
        this.$emit('refresh-prescriptions');
        this.processing = false;
        this.showDialog = false;
      } catch (err) {
        this.showAlert(err);
        this.processing = false;
      }
    }

  }
};